/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { sessionStorage } from "../../../../../assets/myjs/glovar.js";
export default {
  name: "zyInfo",
  data() {
    return {
      zyInfoData: {}
      //   zyInfoData: {
      //     fullname: "了对接肯定凯撒奖到里面撒开聊大幅扫卡了",
      //   },
    };
  },

  created() {
    this.infoApi();
    // var ywTrackInfoData = sessionStorage.getItem("ywTrackdata");
    // console.log(JSON.parse(ywTrackInfoData))
    // this.ywTrackInfoData = JSON.parse(ywTrackInfoData);
  },

  methods: {
    onClickLeft() {
      this.$router.push("/ywLIst");
      sessionStorage.removeItem("ywTrackdata");
    },
    infoApi() {
      var xmbhZB = sessionStorage.getItem("xmbhZB");
      var url = _baseUrl + `/ywgz/tbgl/tbGl/findTbXxByXmBh/${xmbhZB}.do?`;
      axios.get(url).then(res => {
        this.zyInfoData = res.data;
        console.log(this.zyInfoData);
      });
    }
  }
};